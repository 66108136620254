import { state } from ":mods";
import { ModalViewProps, ConfirmDialogViewProps } from "./model";

export const $modals = state.createGlobal<ModalViewProps[]>([]);
export const $confirm_dialogs = state.createGlobal<ConfirmDialogViewProps[]>([]);
export const actions = {
  pushModal(props: ModalViewProps) {
    $modals.set((s) => [...s, props]);
  },
  pushConfirmDialog(props: ConfirmDialogViewProps) {
    $confirm_dialogs.set((s) => [...s, props]);
  },
};
